import React from "react"
import { Link } from "gatsby"

import HeaderResources from "../../components/header_resources"
import BlogPic from "../../static/images/blog/blog_01-960x550.jpg"

export default function Blog() {
  const heroTitle = "Our Blog"

  return (
    <HeaderResources heroTitle={heroTitle}>
      <h2 class="h2 h2-sm">{heroTitle}</h2>
      <div class="row">
        <div class="col-12 col-lg-6 col-md-6">
          <Link to="/resources/blog/selecting-a-fund-manager" className={"post-wrapper"}>
            <div class="post-featured-image">
              <figure class="post-featured-image-hover">
                <img src={BlogPic} alt="Selecting A Fund Manager" class="img-fluid" />
              </figure>
            </div>
            <div class="post-content-wrapper">
              <h3 class="h5 h5-sm">Selecting A Fund Manager</h3>
              <div class="post-button-wrapper">
                <div class="continue-reading" title="Selecting A Fund Manager">
                  Continue Reading<span></span>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </HeaderResources>
  )
}
